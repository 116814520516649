<template>
  <RenderlessXUser v-slot="{ xIsAuthenticated, user }">
    <div>
      <PageHeader />
      <div class="s-home-delivery">
        <transition name="fade">
          <VideoBackground class="video-wrapper">
            <VideoPlayer
autoplay loop muted playsinline :posters="[
              {
                src: '/images/hero-placeholder-desktop-blur.webp',
                media: '(min-width: 768px)'
              },
              {
                src: '/images/hero-placeholder-mobile-blur.webp',
                media: '(max-width: 767px)'
              }
            ]" :sources="[
                          {
                            src: 'https://theflowery.s3.amazonaws.com/videos/hero-video-opt.webm',
                            type: 'video/webm',
                            media: '(min-width: 768px)'
                          },
                          {
                            src: 'https://theflowery.s3.amazonaws.com/videos/hero-video-mobile-opt.webm',
                            type: 'video/webm',
                            media: '(max-width: 767px)'
                          }
                        ]" />
            <template #placeholder>
              <img
class="w-full h-full object-cover hidden md:inline-block"
                src="/images/hero-placeholder-desktop-blur.webp" alt="Hero Video Placeholder" />
              <img
class="w-full h-full object-cover md:hidden" src="/images/hero-placeholder-mobile-blur.webp"
                alt="Hero Video Placeholder" />
            </template>
          </VideoBackground>
        </transition>

        <div
          class="cannabis-delivery flex flex-col w-full h-full justify-center items-center text-left max-w-screen-md mx-auto z-9 relative mt-2 md:mt-0">
          <div
            class="p-0 md:pl-5 md:pb-2 -mt-4 md:-mt-24 mx-auto tracking-tighter md:tracking-normal flex flex-col justify-center md:text-left md:justify-start text-center w-full">
            <h1
              class="font-main font-bold uppercase pb-3 md:pb-2 lg:pb-3 text-5xl lg:text-7xl w-full text-center text-white md:text-left">
              <span class="text-4xl md:text-5xl">Flowery to</span> <br />the People
            </h1>
            <h2 class="text-center font-alt font-semibold md:text-left text-2xl mb-5 text-white">
              Cannabis delivered.
            </h2>
          </div>

          <ClientOnly>
            <RenderlessXSelectLocation v-slot="xselect">
              <div class="flex w-10/12 md:w-full">
                <HomePageSelectLocation @open-region-modal="openRegionModal" />
                <ModalRegion
:show="currentModal === 'region'" :show-with-error="showRegionModalWithError" :user="user"
                  :delivery="xselect.xFindNextDeliveryDate" @prev="() => {
                    showRegionModalWithError = false
                    openDeliveryModal()
                  }
                  " @close="() => {
                                          showRegionModalWithError = false
                                          closeAllModals()
                                        }
                                        " />
                <ModalDelivery
:show="currentModal === 'delivery'" @close="closeAllModals" @next="openRegionModal"
                  @error="handleAddressError" />
              </div>
            </RenderlessXSelectLocation>
            <template #fallback>
              <div class="flex w-10/12 md:w-full">
                <div style="height: 84px" class="w-full bg-white rounded-xl md:rounded-full py-3 px-3 mt-3 md:mt-0">
                </div>
              </div>
            </template>
          </ClientOnly>

          <div
v-if="!xIsAuthenticated"
            class="hidden md:block font-alt font-medium text-base underline pl-5 pt-5 text-white w-full cursor-pointer"
            @click.prevent.self="openLoginModal">
            Already have an Account? Login
          </div>

          <div
v-if="xIsAuthenticated"
            class="hidden md:block font-alt font-medium text-base underline pl-5 pt-5 text-white w-full cursor-pointer"
            @click.prevent.self="openDeliveryModal">
            Add A New Address
          </div>
        </div>
      </div>
      <!-- the first product returns false. I think when the user didn't want to see products at the homepage, we should avoid showing empty values there. -->
      <template v-if="GET_HEADER_DATA.products.length && GET_HEADER_DATA.products[0].product !== false">
        <div class="s-product-block relative">
          <ModalAddedToCartSuccess :show="!!productName" :product-name="productName" @close="productName = ''" />
          <div
            class="pt-4 px-6 rounded-b-2xl pb-3.5 md:p-6.5 flex font-alt items-center justify-between font-medium leading-12 text-center text-black text-xl lg:text-3xl">
            <button
              class="w-10 h-10 rounded-1/2 lg:w-12 lg:h-12 border border-gray-light flex justify-center items-center"
              @click="slidePrev">
              <span class="py-3">
                <IconCaretLeft class="h-5 w-5" />
              </span>
            </button>
            <div>Flowery Favorites</div>
            <button
              class="w-10 h-10 rounded-1/2 lg:w-12 lg:h-12 border border-gray-light flex justify-center items-center"
              @click="slideNext">
              <span class="py-3">
                <IconCaretRight class="h-5 w-5" />
              </span>
            </button>
          </div>
          <Swiper ref="swiper" v-bind="swiperSettings">
            <SwiperSlide v-for="(slide, indx) in GET_HEADER_DATA.products" :key="indx" class="max-w-xl" :index="indx">
              <ProductCard
                :woocommerce-id="slide.product.ID.toString()" 
                :sort="slide.product.lineage"
                :title="slide.product.post_title" :thc="slide.product.thc_total" :price="slide.product.regular_price"
                :concentration="slide.product.concentration" :unit="slide.product.uom" :salve="slide.product.salve_id"
                :category="slide.product.category" :discount-price="slide.product.price" :slug="slide.product.post_name"
                :image="slide.product.featured_image" layout-strategy="fixed" @add-to-cart="setProductName" />
            </SwiperSlide>
          </Swiper>
        </div>
      </template>
      <div class="s-shop p-3 pt-7 relative z-10" :style="backgroundStyles">
        <div class="s-shop-block grid gap-3 grid-cols-2 md:grid-cols-3 max-w-screen-lg mx-auto">
          <HomePagePromoSlider
:shop-data="homeData.featured_slider || []"
            class="s-shop-item item-main col-span-2 row-span-2" />

          <NuxtLink :to="homeData['top_right_grid_link']" class="s-shop-item item-1 cursor-pointer md:col-span-1">
            <SalveImage
:url="homeData['top_right_grid']" :modifiers="getImageModifiers(homeData['top_right_grid'])"
              class="static" loading="lazy" />
            <SalveImage
v-if="homeData['top_right_grid_animate']" :url="homeData['top_right_grid_animate']"
              :modifiers="getImageModifiers(homeData['top_right_grid_animate'])" class="active" loading="lazy" />
          </NuxtLink>

          <NuxtLink :to="homeData['middle_right_grid_link']" class="s-shop-item item-1 cursor-pointer md:col-span-1">
            <SalveImage
:url="homeData['middle_right_grid']"
              :modifiers="getImageModifiers(homeData['middle_right_grid'])" class="static" loading="lazy" />
            <SalveImage
v-if="homeData['middle_right_grid_animate']" :url="homeData['middle_right_grid_animate']"
              :modifiers="getImageModifiers(homeData['middle_right_grid_animate'])" class="active" loading="lazy" />
          </NuxtLink>

          <a
:href="homeData['bottom_left_grid_link']"
            class="s-shop-item item-1 cursor-pointer col-span-2 md:col-span-1">
            <SalveImage
:url="homeData['bottom_left_grid']" :modifiers="getImageModifiers(homeData['bottom_left_grid'])"
              class="static w-full" loading="lazy" />
            <SalveImage
v-if="homeData['bottom_left_grid_animate']" :url="homeData['bottom_left_grid_animate']"
              :modifiers="getImageModifiers(homeData['bottom_left_grid_animate'])" class="active" loading="lazy" />
          </a>

          <a :href="homeData['bottom_middle_grid_link']" class="s-shop-item item-1 cursor-pointer md:col-span-1">
            <SalveImage
:url="homeData['bottom_middle_grid']"
              :modifiers="getImageModifiers(homeData['bottom_middle_grid'])" class="static" loading="lazy" />
            <SalveImage
v-if="homeData['bottom_middle_grid_animate']" :url="homeData['bottom_middle_grid_animate']"
              :modifiers="getImageModifiers(homeData['bottom_middle_grid_animate'])" class="active" loading="lazy" />
          </a>

          <a :href="homeData['bottom_right_grid_link']" class="s-shop-item item-1 cursor-pointer md:col-span-1">
            <SalveImage
:url="homeData['bottom_right_grid']"
              :modifiers="getImageModifiers(homeData['bottom_right_grid'])" class="static" loading="lazy" />
            <SalveImage
v-if="homeData['bottom_right_grid_animate']" :url="homeData['bottom_right_grid_animate']"
              :modifiers="getImageModifiers(homeData['bottom_right_grid_animate'])" class="active" loading="lazy" />
          </a>
        </div>
      </div>

      <HomePageLearnMore :content="homeData.content_block" />

      <div class="max-w-screen-2xl mx-auto">
        <div class="flex flex-col lg:flex-row mt-10 md:m-0 justify-between">
          <HomePageFAQ :faq="homeData.faqs" />
          <HomePageStrainDrops
:title="homeData.strain_drops_title" :content="homeData.strain_drops_content"
            :products="homeData.strain_drops_products" />
        </div>
      </div>
      <div id="terrific-live"></div>
      <PageFooter />
    </div>
  </RenderlessXUser>

</template>

<script>
import { mapGetters, mapActions, useStore } from 'vuex'
import { imageData } from '@/utils/images'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper'
import 'swiper/css'
import ModalAddedToCartSuccess from '@/components/modal/ModalAddedToCartSuccess.vue'

export default {
  components: {
    Swiper,
    SwiperSlide,
    ModalAddedToCartSuccess
  },
  data: () => ({
    productName: '',
    showRegionModalWithError: false,
    currentModal: null,
    showVideo: false,
    swiperSettings: {
      loop: true,
      centeredSlides: true,
      modules: [Navigation],
      slidesPerView: 'auto',
      loopedSlides: this?.GET_HEADER_DATA?.products || 0,
      breakpoints: {
        768: {
          slidesPerView: 2
        },
        1280: {
          slidesPerView: 3
        },
        1536: {
          slidesPerView: 4
        }
      }
    },
    isAgeModalVisible: false
  }),
  computed: {
    ...mapGetters(['GET_HEADER_DATA']),
    ...mapGetters('authuser', ['xCurrentUser', 'xIsAuthenticated']),
    ...mapGetters('user-region', ['xSelectedAddress']),
    ...mapGetters('auth', ['xAuthStateKnown']),

    backgroundStyles() {
      const { path, provider } = imageData('/grunge-bg.jpg')
      return {
        backgroundImage: `url('${this.$img(path, { quality: 50 }, { provider })}')`
      }
    }
  },
  methods: {
    ...mapActions('auth', ['xCloseLoginPopup', 'xOpenLoginPopup']),

    handleAddressError() {
      this.showRegionModalWithError = true
      this.openRegionModal()
    },

    setProductName(productName) {
      this.productName = productName.replace(/&amp;/g, '&')
      setTimeout(() => {
        this.productName = ''
      }, 4000)
    },

    slidePrev() {
      this.$refs.swiper.$el.swiper.slidePrev()
    },
    slideNext() {
      this.$refs.swiper.$el.swiper.slideNext()
    },
    closeModal() {
      localStorage.isHomeAgeModalVisible = 0
    },
    show() {
      setTimeout(() => {
        this.showVideo = true
        this.$emit('loading')
      }, 1000)
    },
    openDeliveryModal(address) {
      this.currentModal = 'delivery'
      // console.log("homepage show delivery");
    },

    openRegionModal(address) {
      this.currentModal = 'region'
      // console.log("homepage show region");
    },

    openLoginModal() {
      this.closeAllModals()
      this.xOpenLoginPopup()
    },

    closeAllModals() {
      // console.log("close modals");
      this.xCloseLoginPopup()
      this.currentModal = null
    },
    getImageModifiers(url) {
      const isGif = url.endsWith('.gif')
      const modifiers = {
        w: 333, // Width
        h: 333, // Height
        ...(isGif ? { q: 50 } : {}) // Reduce quality for GIFs
      }

      return modifiers
    }
  }
}
</script>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { $api } from '@/utils/api'
import { useYoastData } from '@/composables/useYoastData'
import { createSchemaScript } from '~/utils/schema'

import { useHead } from '@vueuse/head';

onMounted(() => {
  // Load the external script
  const script = document.createElement('script');
  script.src = 'https://terrific.live/live-session.js';
  script.setAttribute('embed-id', 'JKuyEzaK07ydbLzYhqv4');
  script.setAttribute('company', 'flowery');
  script.setAttribute('element-id', 'terrific-live');
  script.setAttribute('font-family', 'DM MONO');
  document.head.appendChild(script);
});

const store = useStore()
const { injectYoastDataFrom } = useYoastData()

const { data: homeData, error } = await useAsyncData(() => $api.HomeRequests.getHomeData())

if (error.value) {
  throw createError({ statusCode: 500, statusMessage: 'Page Error' })
}

if (!homeData.value) {
  throw createError({ statusCode: 404, statusMessage: 'Page Not Found' })
}

injectYoastDataFrom(homeData.value.yoast_title, homeData.value.yoast_meta)

// Get state
const storeName = useState('storeName')
const storeUrl = useState('storeUrl')
const logoUrl = useState('logoUrl', () => 'https://ik.imagekit.io/salve/images/brand/logo.png?tr=q-75,w-350')
const facebookUrl = useState('facebookUrl')
const instagramUrl = useState('instagramUrl')
const phoneNumber = useState('phoneNumber', () => '+1-305-912-2929')

// Create organization schema
const organizationSchema = computed(() => ({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  '@id': `${storeUrl.value}/#organization`,
  name: storeName.value,
  url: storeUrl.value,
  logo: logoUrl.value,
  sameAs: [
    facebookUrl.value,
    instagramUrl.value
    // Add other social media profiles here
  ].filter(Boolean),
  contactPoint: {
    '@type': 'ContactPoint',
    telephone: phoneNumber.value,
    contactType: 'customer service'
  }
}))

// Update head with only the organization schema
useHead(() => ({
  script: [createSchemaScript(organizationSchema.value)]
}))
</script>

<style scoped lang="postcss">
.pac-container {
  width: 200px !important;
  z-index: 10000 !important;

  .pac-item {
    padding: 100px !important;
  }
}

.s-shop-item {
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.25);

  .static {
    background: white;
    display: block;
  }

  .active {
    display: none;
  }
}

.s-shop-item:hover {
  .static {
    display: none;
  }

  .active {
    display: block;
  }
}

.fade {
  backface-visibility: hidden;
}

.fade-enter-active {
  transition: opacity 1s;
}

.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter {
  opacity: 0;
}

.fade-leave-to {
  opacity: 0;
}

.s-home-delivery {
  margin-top: 60px;
  height: 800px;
  position: relative;

  .video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 772px) {
    height: 550px;
  }
}

.s-product-block {
  height: 100%;
  @apply md:mt-0 md:mb-10;

  .product-card {
    @apply md:mx-5;
  }
}

.hooper {
  @apply my-6;
}

.s-shop {
  background-size: cover;
  z-index: 1;

  .s-shop-block {
    @apply -mb-12;
  }
}

.s-faq-block {
  max-width: 1440px;

  .s-faq-block-container {
    @media screen and (max-width: 772px) {
      padding: 0 0 0 0;
      margin: 0 auto;
    }

    @media screen and (max-width: 640px) {
      padding: 0;
      max-width: inherit;
      @apply flex flex-col-reverse mt-10;
    }
  }
}



terrific-live-session.minimized {
  right: 30px;
  left: unset;
  transform-origin: right bottom;
  z-index: 100 !important;
}

@media(max-width: 768px) {
  terrific-live-session.minimized {
    bottom: 100px;
  }
}
</style>

<style>
.s-product-block-hooper .hooper-slide {
  @apply flex h-full flex-row items-center mx-1;
}
</style>